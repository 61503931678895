
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/views/modals/Card.vue";
import KTCreateAccountModal from "@/components/modals/wizards/CreateAccountModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "create-account",
  components: {
    KTModalCard,
    KTCreateAccountModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Create Account", ["Modals", "Wizards"]);
    });
  },
});
